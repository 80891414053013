<template>
  <section>
    <a-input-group>
      <a-row :gutter="8">
        <a-space class="fa">
          <a-col>
            <a-input v-model="g_name" placeholder="请输入商品名称" />
          </a-col>
          <a-cascader
          style="margin-top:5px"
            :fieldNames="{ label: 't_name', value: 't_id', children: 'child' }"
            :options="classOption"
            placeholder="请选择分类"
            @change="serchChange"
          />
          <a-button @click="goods_list" type="primary"> 搜索 </a-button>
        </a-space>
      </a-row>
    </a-input-group>
    <br />
    <a-button @click="add" type="primary">
      <a-icon type="plus" />新增
    </a-button>
    <br />
    <br />
    <a-table
      :data-source="dataList"
      :rowKey="(row) => row.g_id"
      :pagination="pagination"
      :loading="tabLoading"
      @change="pageChange"
    >
      <a-table-column
        key="g_name"
        title="商品名称"
        data-index="g_name"
      />
      <a-table-column
        key="t_pid_txt"
        title="一级分类"
        data-index="t_pid_txt"
      />
      <a-table-column
        key="t_id_txt"
        title="二级分类"
        data-index="t_id_txt"
      />
      <a-table-column key="g_price" title="零售" data-index="g_price" />
      <a-table-column key="g_original_price" title="原价" data-index="g_original_price" />
      <a-table-column
        key="g_stock"
        title="库存"
        data-index="g_stock"
      />
      <a-table-column
        key="g_stock_min"
        title="库存预警数"
        data-index="g_stock_min"
      />
      <a-table-column key="g_spec" title="产品规格" data-index="g_spec" />
      <a-table-column
        key="g_type"
        title="产品型号"
        data-index="g_type"
      />
      <a-table-column key="imgs" title="图片" data-index="imgs">
        <template slot-scope="imgs">
          <a-space>
            <!-- <a-tooltip v-for="(item, index) in imgs" :key="index">
              <a-avatar size="large" :src="item.url" />
              <template slot="title">
                <img :src="item.url" style="width: 180px" alt="" />
              </template>
            </a-tooltip> -->
            <a-tooltip>
              <a-avatar size="large" :src="imgs[0].url" />
              <template slot="title">
                <img :src="imgs[0].url" style="width: 180px" alt="" />
              </template>
            </a-tooltip>
          </a-space>
        </template>
      </a-table-column>
      <a-table-column
        key="g_sort"
        title="排序"
        data-index="g_sort"
      />
      <a-table-column key="action" width="200px" title="操作">
        <template slot-scope="text">
          <a-space>
            <a-button @click="deleteConfirm(text)" type="danger"
              ><a-icon type="delete"
            /></a-button>
            <a-button @click="update(JSON.stringify(text))" type="primary"
              ><a-icon type="edit"
            /></a-button>
          </a-space>
        </template>
      </a-table-column>
    </a-table>
    <a-modal
      v-model="alert"
      :title="alertTitle"
      width="800px"
      :confirm-loading="subLoading"
      cancelText="取消"
      okText="确定"
      @ok="handleOk"
    >
      <a-form-model
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="商品名称">
          <a-input v-model="form.g_name" />
        </a-form-model-item>
        <a-form-model-item label="分类">
          <a-cascader
            ref="cascader"
            :fieldNames="{ label: 't_name', value: 't_id', children: 'child' }"
            :options="classOption"
            placeholder="请选择分类"
            @change="onChange"
          />
        </a-form-model-item>
        <a-form-model-item label="产品规格">
          <a-input v-model="form.g_spec" />
        </a-form-model-item>
        <a-form-model-item label="产品型号">
          <a-input v-model="form.g_type" />
        </a-form-model-item>
        <a-form-model-item label="零售">
          <a-input v-model="form.g_price" />
        </a-form-model-item>
        <a-form-model-item label="原价">
          <a-input v-model="form.g_original_price" />
        </a-form-model-item>
        <a-form-model-item label="库存">
          <a-input v-model="form.g_stock" />
        </a-form-model-item>
        <a-form-model-item label="库存预警数">
          <a-input v-model="form.g_stock_min" />
        </a-form-model-item>
        <a-form-model-item label="图片">
          <div class="clearfix">
            <a-upload
              action="http://api2.jcwh.xyz/common/Uploads/shop"
              list-type="picture-card"
              :file-list="form.imgs"
              @preview="handlePreview"
              @change="handleChange"
            >
              <a-icon type="plus" />
              <div class="ant-upload-text">上传</div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
          (支持格式：jpg,png 大小：2M以下 建议尺寸：750px * 750px)<br />
        </a-form-model-item>
        <a-form-model-item label="详情">
          <quill-editor
            v-model="form.g_con"
            ref="myQuillEditor"
            :options="editorOption"
          >
          </quill-editor>
          <!-- (图片大小：2M以下)<br /> -->
        </a-form-model-item>
        <a-form-model-item label="排序">
          <a-input v-model="form.g_sort" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </section>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import Vue from "vue";
import qs from "qs";
import {
  goods_list,
  goods_add,
  goods_edit,
  goods_del,
  type_list,
} from "@/assets/api/api";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);
import { quillEditor, Quill } from "vue-quill-editor";
import { ImageExtend, QuillWatch } from "quill-image-extend-module";
Quill.register("modules/ImageExtend", ImageExtend);
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        placeholder: "请输入详情",
        modules: {
          ImageExtend: {
            loading: true,
            name: "file",
            action: "https://xcxautopartcq.shop.lyxiu.com/Uploads/file",
            response: (res) => {
              return res.data;
            },
          },
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              ["blockquote", "code-block"], //引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: "ordered" }, { list: "bullet" }], //列表
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              [{ direction: "rtl" }], // 文本方向
              [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ align: [] }], //对齐方式
              ["clean"], //清除字体样式
              ["image"], //上传图片、上传视频
            ],
            handlers: {
              image: function () {
                QuillWatch.emit(this.quill.id);
              },
            },
          },
        },
      },
      previewVisible: false,
      previewImage: "",
      upLoading: false,
      tabLoading: false,
      subLoading: false,
      pagination: {
        defaultPageSize: 15,
        showTotal: (total) => `共 ${total} 条数据`,
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      form: {
        g_name: "",
        t_pid: "",
        t_id: "",
        g_price: "",
        g_original_price:"",
        g_stock: "",
        g_stock_min: "",
        g_spec: "",
        g_type: "",
        g_sort: "",
        g_con: "",
        imgs: [],
      },
      alert: false,
      alertTitle: "",
      page: 1,
      limit: 15,
      dataList: [],
      classOption: [],
      g_name: "",
      openList: [
        {
          text: "开启",
          val: 1,
        },
        {
          text: "不开启",
          val: 0,
        },
      ],
      disabledStatus: false,
      t_pid: "",
      t_id: "",
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ file, fileList }) {
      this.form.imgs = fileList;
      if (file.status == "done") {
        let item = {
          uid: this.form.imgs.length,
          name: this.form.imgs.length + ".png",
          status: "done",
          url: file.response.data,
        };
        this.form.imgs[this.form.imgs.length - 1] = item;
      }
    },
    add() {
      this.alert = true;
      this.alertTitle = "新增";
      (this.form = {
        g_name: "",
        t_pid: "",
        t_id: "",
        g_price: "",
        g_original_price:"",
        g_stock: "",
        g_stock_min: "",
        g_spec: "",
        g_type: "",
        g_sort: "",
        g_con: "",
        imgs: [],
      }),
        (this.disabledStatus = false);
      setTimeout(() => {
        let status = false;
        for (var i in this.$refs) {
          status = true;
        }
        if (status) {
          this.$refs.cascader.sValue = [];
        }
      }, 0);
    },
    onChange(value) {
      this.form.t_pid = value[0];
      this.form.t_id = value[1];
    },
    serchChange(value) {
      this.t_pid = value[0];
      this.t_id = value[1];
    },
    deleteConfirm(text) {
      this.$confirm({
        title: "提示",
        content: "您是否要删除该项信息?",
        okText: "确定",
        okType: "primary",
        cancelText: "取消",
        onOk: () => {
          this.goods_del(text.g_id);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    goods_del(id) {
      this.tabLoading = true;
      goods_del(qs.stringify({ g_id: id })).then((res) => {
        this.tabLoading = false;
        if (res.data.code == 0) {
          this.$message.success("删除成功");
          this.goods_list();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    update(text) {
      this.alert = true;
      this.alertTitle = "修改";
      this.form = JSON.parse(text);
      this.disabledStatus = true;
      setTimeout(() => {
        let status = false;
        for (var i in this.$refs) {
          status = true;
        }
        if (status) {
          this.$refs.cascader.sValue = [this.form.t_pid, this.form.t_id];
        }
      }, 0);
    },
    handleOk() {
      if (this.form.g_name == "") {
        this.$message.error("请输入名称");
      } else if (this.form.t_pid == "") {
        this.$message.error(
          "请选择一级分类"
        );
      } else if (this.form.t_id == "") {
        this.$message.error(
          "请选择二级分类"
        );
      } else if (this.form.g_price == "") {
        this.$message.error("请输入零售");
      } else if (this.form.g_original_price == "") {
        this.$message.error("请输入原价");
      } else if (this.form.g_stock == "") {
        this.$message.error("请输入库存");
      } else if (this.form.g_stock_min == "") {
        this.$message.error(
          "请输入库存预警数"
        );
      } else if (this.form.g_sort == "") {
        this.$message.error("请输入排序");
      } else if (this.form.g_con == "") {
        this.$message.error("请输入详情");
      } else if (!this.form.imgs.length) {
        this.$message.error("请上传图片");
      } else {
        var data = JSON.stringify(this.form);
        var item = JSON.parse(data);
        item.imgs = JSON.stringify(this.form.imgs);
        this.subLoading = true;
        if (this.alertTitle == "新增") {
          goods_add(qs.stringify(item)).then((res) => {
            this.subLoading = false;
            if (res.data.code == 0) {
              this.alert = false;
              this.goods_list();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else if (this.alertTitle == "修改") {
          var data = JSON.stringify(this.form);
          var item = JSON.parse(data);
          item.imgs = JSON.stringify(this.form.imgs);
          this.subLoading = true;
          goods_edit(qs.stringify(item)).then((res) => {
            this.subLoading = false;
            if (res.data.code == 0) {
              this.alert = false;
              this.goods_list();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      }
    },
    pageChange(e) {
      this.page = e.current;
      this.goods_list();
    },
    goods_list() {
      this.tabLoading = true;
      goods_list(
        qs.stringify({
          page: this.page,
          limit: this.limit,
          g_name: this.g_name,
          t_pid: this.t_pid,
          t_id: this.t_id,
        })
      ).then((res) => {
        this.tabLoading = false;
        if (res.data.code == 0) {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.data.total);
          this.pagination = pagination;
          for (let i = 0; i < res.data.data.data.length; i++) {
            for (let j = 0; j < res.data.data.data[i].imgs.length; j++) {
              (res.data.data.data[i].imgs[j].status = "done"),
                (res.data.data.data[i].imgs[j].name = j + ".png"),
                (res.data.data.data[i].imgs[j].uid = j);
            }
          }
          this.dataList = res.data.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    type_list() {
      type_list().then((res) => {
        if (res.data.code == 0) {
          this.classOption = res.data.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.goods_list();
    this.type_list();
  },
  computed: {},
};
</script>